<script>
import Heading from "./Heading";

export default {
    name: "BaseTitle",

    extends: Heading,

    props: {
        size: {
            type: String,
            default: "text-h5",
        },
        sizeMobile: {
            type: String,
            default: "text-body-2",
        },
        weight: {
            type: String,
            default: "bold",
        },
    },
};
</script>
