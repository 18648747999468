// Vuetify Documentation https://vuetifyjs.com

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#4f6f78",
        secondary: "#518573",
        accent: "#57748F"
      },
      dark: {
        primary: "#50778D",
        secondary: "#0B1C3D",
        accent: "#204165"
      }
    }
  }
});
