<template>
    <v-theme-provider :dark="dark">
        <div>
            <base-img
                :src="require('@/assets/apply-logo-dark.png')"
                contain
                max-width="128"
                min-height="78"
                width="100%"
            />

            <base-title
                size="text-body-1"
                space="4"
                title="WORKING TO HOUSE THOSE WITH BARRIERS"
                weight="regular"
            />

            <base-body>
                At Apply Oregon we are enabling a new class of tenants by
                bridging the gap between property managers and applicants. We
                leverage our 30 years of experience in rental screening and a
                powerful technology platform to discover barriers that prevent
                applicants from renting and we work to eliminate them.
            </base-body>

            <base-btn class="mb-12" color="white" outlined>
                More Info
            </base-btn>
        </div>
    </v-theme-provider>
</template>

<script>
export default {
    name: "BaseInfo",

    props: {
        title: String,
        dark: Boolean,
    },

    data: () => ({
        business: [
            {
                icon: "mdi-map-marker-outline",
                title: "Address",
                text: "8553 N. Beach St. Ste. 227<br>Fort Worth, Texas 76137",
            },
            {
                icon: "mdi-cellphone",
                title: "Phone",
                text: "01 (800) 433 744<br>01 (800) 433 633",
            },
            {
                icon: "mdi-email",
                title: "Email",
                text: "john@vuetifyjs.com<br>heather@vuetifyjs.com",
            },
        ],
    }),
};
</script>
